<template>
     <div class="optimize_history">
          <div class="opt_his_con">
               <div class="layer1_on flex_row_start_center">
                    <span class="txt1" @click="toDetail('/prototype/sample/apply',{})">{{L['样品申请']}}</span>
                    <span class="el-icon-arrow-right"></span>
                    <span class="txt2">{{L['历史记录']}}</span>
               </div>
               <div class="layer2_on">
                    <div class="state_con flex_row_start_center">
                         <div :class="{state:true,chosen:state==0}" @click="changeState(0)">{{L['全部']}}</div>
                         <div :class="{state:true,chosen:state==1}" @click="changeState(1)">{{L['处理中']}}</div>
                         <div :class="{state:true,chosen:state==2}" @click="changeState(2)">{{L['已完成']}}</div>
                         <div :class="{state:true,chosen:state==3}" @click="changeState(3)">{{L['已关闭']}}</div>
                    </div>
                    <div class="layer_table">
                         <el-table ref="multipleTable" :data="hisList" style="width: 100%">
                              <el-table-column property="applySn" :label="L['申请单号']" align="center" width="200" />
                              <el-table-column property="createTime" :label="L['提交时间']" align="center" width="300" />
                              <el-table-column property="storeName" :label="L['供应商']" align="center" width="200" />
                              <el-table-column property="stateValue" :label="L['状态']" align="center" width="200" />
                              <el-table-column :label="L['操作']" width="220" align="center">
                                   <template #default="scope">
                                        <div class="options">
                                             <span class="txt17"
                                                  @click="toDetail('/prototype/sample/detail',scope.row)">{{L['查看详情']}}</span>
                                             <span class="txt17" @click="cancelApply(scope.row)"
                                                  v-if="scope.row.state==1" style="margin-left: 10px;">{{L['取消申请']}}</span>
                                             <span class="txt17" @click="del(scope.row)" v-if="scope.row.state==3"
                                                  style="margin-left: 10px;">{{L['删除']}}</span>
                                        </div>
                                   </template>
                              </el-table-column>
                         </el-table>
                    </div>
                    <!-- 分页 -->
                    <div class="flex_row_center_center sld_pagination">
                         <el-pagination @current-change="handleCurrentChange" v-model:currentPage="page.current"
                              :page-size="page.pageSize" layout="prev, pager, next, jumper" :total="page.total"
                              :hide-on-single-page="true">
                         </el-pagination>
                    </div>

               </div>
          </div>
     </div>
</template>
<script>
     import { ref, getCurrentInstance, onMounted, reactive } from 'vue';
     import { ElMessage } from 'element-plus';
     import { useRouter } from 'vue-router';
     export default {
          setup() {
               const { proxy } = getCurrentInstance()
               const L = proxy.$getCurLanguage()
               const router = useRouter()

               const listParam = reactive({
                    current: 1
               })
               const hisList = ref([])
               const state = ref(0)
               const page = ref({})
               const getDeHis = () => {
                    proxy.$get('v3/business/front/sample/apply/list', listParam).then(res => {
                         if (res.state == 200) {
                              hisList.value = res.data.list
                              page.value = res.data.pagination
                         }
                    })
               }

               const changeState = (state1) => {
                    state.value = state1
                    listParam.current = 1
                    listParam.state = state1
                    if (state1 == 0) delete listParam.state
                    getDeHis()
               }

               const toDetail = (link, scoItem) => {

                    let query = {}

                    if (Object.keys(scoItem).length) {
                         query.applyId = scoItem.applyId
                    }

                    router.push({
                         path: link,
                         query
                    })
               }

               const handleCurrentChange = (e) => {
                    listParam.current = Math.floor(e);
                    getDeHis()
               }


               const cancelApply = (scopeItem) => {
                    let { applyId } = scopeItem
                    proxy.$confirm(L['是否确认取消该条申请？取消后您可重新发起申请'], L['提示'], {
                         confirmButtonText: L['确认'],
                         cancelButtonText: L['取消'],
                         type: 'warning'
                    }).then(() => {
                         proxy.$post('v3/business/front/sample/apply/cancel', { applyId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   listParam.current = 1
                                   getDeHis()
                              } else {
                                   ElMessage(res.msg)
                              }
                         })
                    })
               }


               const del = (scopeItem) => {
                    let { applyId } = scopeItem
                    proxy.$confirm(L['是否确认删除该条申请记录？'], L['提示'], {
                         confirmButtonText: L['确认'],
                         cancelButtonText: L['取消'],
                         type: 'warning'
                    }).then(() => {
                         proxy.$post('v3/business/front/sample/apply/delete', { applyId }).then(res => {
                              if (res.state == 200) {
                                   ElMessage.success(res.msg)
                                   listParam.current = 1
                                   getDeHis()
                              } else {
                                   ElMessage(res.msg)
                              }
                         })
                    })
               }

               onMounted(() => {
                    getDeHis()
               })

               return {

                    toDetail,
                    hisList,
                    state,
                    changeState,
                    page,
                    handleCurrentChange,
                    cancelApply,
                    del,
                    L
               }
          }
     }
</script>
<style lang="scss">
     .layer_table {
          .has-gutter {
               background: #F4F4F4 !important;
          }

          .el-table thead tr {
               background-color: #F4F4F4;
          }

          .el-table th {
               background-color: #F4F4F4;
          }
     }



     .optimize_history {
          background: #F4F4F4;
          padding-top: 20px;
          padding-bottom: 50px;

          .opt_his_con {
               width: 1200px;
               margin: 0 auto;

               .layer1_on {
                    height: 54px;
                    background: #FFFFFF;
                    padding-left: 32px;

                    .txt1 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #999;
                         margin-right: 5px;
                         cursor: pointer;

                         &:hover {
                              color: $colorMain;
                         }
                    }

                    .txt2 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #000000;
                         margin-left: 5px;
                    }
               }

               .layer2_on {
                    padding: 20px;
                    margin-top: 10px;
                    background: #FFFFFF;

                    .state_con {
                         border-bottom: 1px solid #F2F2F2;

                         .state {
                              height: 40px;
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              color: #666666;
                              margin-right: 30px;
                              line-height: 40px;
                              width: 100px;
                              text-align: center;
                              cursor: pointer;

                              &.chosen {
                                   color: $colorMain;
                                   box-shadow: 0px -2px 0px $colorMain inset;
                              }
                         }


                    }

                    .layer_table {
                         padding: 20px;

                         .options {
                              .txt17 {
                                   &:hover {
                                        color: $colorMain;
                                   }

                                   cursor: pointer;
                              }
                         }
                    }
               }
          }
     }
</style>